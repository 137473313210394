<template>
  <div class="parent mt-2 bg-white rounded shadow">
    <section v-if="!isLoaded" class="h-96">
      <loading :active="!isLoaded" :is-full-page="false" />
    </section>
    <section class="w-full bg-white" v-else>
      <div class="flex flex-col md:flex-row md:items-center gap-x-10 px-5 py-2">
        <div class="flex w-full ">
          <text-input
            rules=""
            label="Command"
            v-model="hexCommand"
            placeholder="e.g. +ATCK=GTRXX"
          />
          <div class="flex items-center ">
            <anchor-button
              :text="'Send'"
              variant="success"
              class="pl-3 cursor-pointer"
              :isLoading="isHexCommandSending"
              @click="handleSendCommand"
            />
          </div>
        </div>
        <ExportAsDropdown @csv="exportAs('csv')" />
      </div>
      <div class="scroll-view mx-5" v-if="!isHexCommandSending">
        <infinite-loading @infinite="fetchVehicleSplunks">
          <div slot="no-more" class="mt-2"></div>
          <div slot="no-results" class="mt-2"></div>
        </infinite-loading>
        <div class="splunk-view" id="scroll-target">
          <div
            v-for="entry in splunkData"
            :key="entry.id"
            :id="`${entry.id}-elem`"
            :class="splunkFormatClass(entry)"
          >
            <span class="white-text">{{ getFormattedDate(entry) }}</span
            ><span class="divider"> | </span
            ><span class="green-text" :class="boldFormatClass(entry)">{{
              entry.data
            }}</span>
          </div>
          <!-- <div id="scroll-target"></div> -->
        </div>
      </div>
      <div
        class="scroll-view mx-5 "
        v-if="isHexCommandSending || splunkData.length === 0"
      >
        <div class=" relative h-96">
          <loading
            :active="isHexCommandSending"
            :is-full-page="false"
            background-color="#000"
            color="#fff"
          />
        </div>
      </div>
      <div class="w-96 " v-if="isExportLoading">
        <loading
          :active.sync="isExportLoading"
          :is-full-page="true"
          :z-index="10"
          :opacity="0.75"
        >
          <div class="p-5 bg-gray-100 rounded-2xl shadow-xl">
            <p>Download in Progress:{{ getProgress }}%</p>
            <div class="w-72">
              <BaseProgressBar color="blue" :percentage="getProgress" />
            </div>
            <div class="mt-5 flex justify-end">
              <button
                class="
          border-0
          rounded-md
          bg-gray-200
          text-gray-900
          px-3
          py-1
          hover:opacity-90
          transition-all
          font-semibold
          ease-linear
        "
                @click="handleCancelExport"
              >
                {{ $t('components.stepNavigation.cancel') }}
              </button>
            </div>
          </div>
        </loading>
      </div>
    </section>
  </div>
</template>

<script>
import { useEndpoints } from '@/composables'
import InfiniteLoading from 'vue-infinite-loading'
import ExportAsDropdown from '@/components/dropdown/ExportAsDropdown'
import BaseProgressBar from '@/components/progress-bar/BaseProgressBar.vue'
import dayjs from 'dayjs'
import AnchorButton from '@/components/form/AnchorButton'
import TextInput from '@/components/form/TextInput'
export default {
  name: 'ViewVehicleTimeline',
  components: {
    // EmptySpace: () => import('@/components/placeholder/EmptySpace'),
    InfiniteLoading,
    ExportAsDropdown,
    BaseProgressBar,
    TextInput,
    AnchorButton,
  },
  data() {
    return {
      hexCommand: '',
      splunkData: [],
      logLimit: 500,
      isLoaded: false,
      isHexCommandSending: false,
      scrollOptions: {
        easing: 'ease-in',
        lazy: false,
        offset: -60,
        force: true,
        cancelable: false,
        x: false,
        y: true,
      },
      format: 'YYYY-MM-DD',
      exportRequestId: null,
      isExportLoading: false,
      exportInterval: null,
      exportInfo: {},
      progressPercentage: 0,
    }
  },
  computed: {
    vehicleId() {
      return this.$route.params.id
    },
    getProgress() {
      return this.progressPercentage
    },
    logOffset() {
      return this.splunkData.length
    },
    logPath() {
      return (
        useEndpoints.vehicle.iotLogs.splunk(this.vehicleId) +
        `?limit=${this.logLimit}&offset=${this.logOffset}`
      )
    },
  },
  watch: {
    exportInfo: {
      immediate: true,
      deep: true,
      handler(data) {
        if (data && data.progress_info) {
          if (data.progress_info.status !== 'in_progress') {
            clearInterval(this.exportInterval)
            this.isExportLoading = false
            this.progressPercentage = 0
            this.exportRequestId = null
            if (
              data.progress_info.status === 'completed' &&
              data.export_record.file_url
            ) {
              const a = document.createElement('a')
              a.style.display = 'none'
              a.href = data.export_record.file_url
              document.body.appendChild(a)
              a.click()
              document.body.removeChild(a)
              this.$notify({
                group: 'generic',
                type: 'success',
                title: 'Success',
                text: 'File has been downloaded successfully',
              })
            } else {
              this.$notify({
                group: 'bottomRight',
                type: 'error',
                title: 'Failed',
                text: `${data.progress_info.failure_reason ??
                  'Failed to download'}`,
              })
            }
          }
        }
      },
    },
  },
  async mounted() {
    if (this.splunkData.length === 0) await this.fetchVehicleSplunks()
  },
  methods: {
    async handleSendCommand() {
      if (this.hexCommand) {
        this.isHexCommandSending = true
        const url = useEndpoints.vehicle.hexCommand(this.vehicleId)
        const data = new FormData()
        data.append('command', this.hexCommand.trim())

        await this.$http
          .post(url, data)
          .then((res) => {
            this.splunkData = []
            this.logLimit = 500
            this.fetchVehicleSplunks()

            this.$notify({
              group: 'generic',
              type: 'success',
              title: 'Success',
              text:
                res.data && res.data.details
                  ? res.data.details
                  : 'Command has been sent',
            })
          })
          .catch((err) => {
            console.log('test-err-hexcommand', { err })
            this.$notify(
              {
                group: 'generic',
                type: 'error',
                title: `Server Error [${err.response.status}]`,
                text: 'Failed to send!',
              },
              5000
            )
          })
          .finally(() => {
            this.hexCommand = ''
            this.isHexCommandSending = false
          })
      }
    },
    getFormattedDate(entry) {
      return new Date(entry['@timestamp']).toLocaleString()
    },
    scrollToBottom(elemId) {
      this.$scrollTo(`#${elemId}`, 200, this.scrollOptions)
    },
    boldFormatClass(entry) {
      return {
        'heavy-text': `${entry.data}`.includes('frame'),
      }
    },
    splunkFormatClass(entry) {
      return {
        'py-3': `${entry.data}`.includes('~~~'),
        'top-padding-3':
          `${entry.data}`.includes('frame') || `${entry.data}`.includes('Data'),
        'px-2': true,
      }
    },

    async fetchVehicleSplunks($state = null) {
      this.$http
        .get(this.logPath)
        .then((res) => {
          console.log(res.data)
          let parsedData = res.data['data']
          if (this.splunkData.length) {
            const toScroll = `${this.splunkData[-1].id}-elem`
            parsedData.forEach((entry) => this.splunkData.unshift(entry))
            // this.splunkData = this.splunkData.concat(parsedData)
            this.scrollToBottom(toScroll)
            console.log('Loaded later : Total ', this.splunkData.length)
          } else {
            this.splunkData = parsedData.reverse()
            console.log('Loaded first : Total ', parsedData.length)
            this.scrollToBottom(`${this.splunkData[-1].id}-elem`)
          }
          if ($state) {
            if (res.data['meta']['count']['total'] > this.splunkData.length) {
              $state.loaded()
            } else {
              $state.complete()
            }
          }
        })
        .catch((err) => {
          console.log(err)
          if ($state) $state.complete()
        })
        .finally(() => {
          if (!this.isLoaded) this.isLoaded = true
          console.log('DONE')
          // var container = this.$el.querySelector('#scroll-target')
          // container.scrollTop = container.scrollHeight
        })
    },

    async exportAs(type) {
      const today = dayjs()
      const startDateTime = today
        .utc()
        .subtract(6, 'day')
        .format()
      const endDateTime = today.utc().format()
      if (type === 'csv') {
        const data = new FormData()
        data.append('vehicle_id', this.$route.params.id)
        data.append('time_from', startDateTime)
        data.append('time_to', endDateTime)
        data.append('data_type', 'splunk')
        const url = useEndpoints.export.iot()

        this.$http
          .post(url, data)
          .then((res) => {
            if (res.data) {
              this.isExportLoading = true
              this.exportRequestId = res.data.id
              this.exportInterval = setInterval(this.fetchExportProgress, 5000)
              this.$notify(
                {
                  group: 'generic',
                  type: 'default',
                  title: 'Processing, please wait...',
                  text:
                    'Please stay on the tab while the operation is being completed.',
                },
                3000
              )
            }
          })
          .catch((err) => {
            console.log('export-request-err', { err })
            this.$notify(
              {
                group: 'bottomRight',
                type: 'error',
                title: 'Failed',
                text: `${
                  err.response.data.message
                    ? err.response.data.message
                    : err.response.data
                }`,
              },
              5000
            )
          })
      }
    },
    async fetchExportProgress() {
      await this.$http
        .get(useEndpoints.export.details(this.exportRequestId))
        .then((res) => {
          this.exportInfo = res.data
          this.progressPercentage =
            parseInt(this.exportInfo.progress_info.progress) || 0

          console.log('res-export', res.data.progress_info.progress)
        })
        .catch((err) => {
          this.$notify({
            group: 'bottomRight',
            type: 'error',
            title: 'Failed',
            text: 'Failed to retrieve',
          })
          console.log('export-get-err', err.response)
        })
    },
    handleCancelExport() {
      this.$http
        .patch(useEndpoints.export.cancel(this.exportRequestId))
        .then(() => {
          this.$notify({
            group: 'generic',
            type: 'success',
            title: 'Success',
            text: 'Cancelled Successfully',
          })
        })
        .catch((err) => {
          this.$notify({
            group: 'bottomRight',
            type: 'error',
            title: 'Failed',
            text: 'Failed to Cancel',
          })
          console.log('export-cancel-err', err.response)
        })
        .finally(() => {
          this.isExportLoading = false
          clearInterval(this.exportInterval)
        })
    },
  },
}
</script>

<style lang="scss">
.splunk-view {
  // border: 5px solid yellow;
  word-wrap: break-word;
}
.parent {
  height: 800px;
  position: relative;
}
.scroll-view {
  // border: 5px solid red;
  max-height: 100%;
  overflow: auto;
  background-color: black;
  // opacity: 70%;
  // color: red;
}
.heavy-text {
  font-weight: bold;
}

.green-text {
  color: chartreuse;
  opacity: 0.9;
}

.divider {
  color: rebeccapurple;
  font-weight: bold;
}

.white-text {
  color: white;
  opacity: 0.85;
}

.top-padding-3 {
  padding-top: 3px;
}
</style>
